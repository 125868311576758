import React from 'react';

import magentoImage1 from 'assets/images/pages/diensten_magentowebshop/1.jpg';
import magentoImage2 from 'assets/images/pages/diensten_magentowebshop/2.jpg';
import magentoImage3 from 'assets/images/pages/diensten_magentowebshop/3.jpg';
import magentoImage4 from 'assets/images/pages/diensten_magentowebshop/4.jpg';

import Title from 'blocks/Title';
import Image from 'blocks/Image';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Button from 'components/Button';

const DienstenBouwenMagento = () => (
  <Layout>
    <Seo image={{ src: magentoImage1 }} />

    <Title
      image={{ src: magentoImage1 }}
      alt="Jouw project, onze uitdaging"
      subtitle="Jouw project, onze uitdaging"
      title="Magento Webshop laten maken door Batao"
    >
      Batao is een full-service E-commerce bureau. Wij hebben een groep enorm
      getalenteerde developers aan boord die alles weten van Magento.
    </Title>

    <Section>
      <Image image={{ src: magentoImage2 }} alt="Magento Webshop laten maken">
        <Heading size="xl" as="h2">
          Magento Webshop laten maken?
        </Heading>

        <Text>
          Je hebt van die bureaus die na de een opdracht van de klant direct aan
          de slag gaan en een generieke webshop bouwen waarbij ze ‘alleen de
          producten en het logo vervangen’. Dat is niet hoe Batao werkt. Wij
          voelen ons verantwoordelijk voor de resultaten van klanten. Daarbij
          denken we niet alléén aan meer verkoop, maar ook bijvoorbeeld aan het
          overbrengen van merkwaarden, de technische kwaliteit en specifieke
          functionaliteiten op maat die jou en jouw klanten net dat beetje extra
          gemak geven.
        </Text>
      </Image>
    </Section>

    <Section>
      <Image
        image={{ src: magentoImage3 }}
        alt="Magento B2B module bouwer"
        position="right"
      >
        <Heading size="xl" as="h2">
          Magento B2B module bouwer
        </Heading>

        <Text>
          Naast Magento webshops bouwen wij namelijk ook enorm goede Magento B2B
          modules. Zo hebben we ‘s werelds beste offerte-module Cart2Quote
          gebouwd, maken we software waarbij je prijzen en bestelknoppen kunt
          verbergen en hebben we een specifiek softwarepakket waarbij
          verkoopprestaties in Magento gekoppeld kunnen worden aan verkopers.
          Daarnaast maken we modules op maat. Kennis die ons in staat stelt om
          veel specifieke en doelgerichte webshops voor onze klanten op te
          leveren.{' '}
        </Text>
      </Image>
    </Section>

    <Section>
      <Image image={{ src: magentoImage4 }} alt="Magento webshop laten maken?">
        <Heading size="xl" as="h2">
          Magento webshop laten maken? Kies dus Batao!
        </Heading>

        <Text spacing="lg">
          Batao is een full-service E-commerce bureau. Wij hebben een groep
          enorm getalenteerde developers aan boord: ervaren programmeurs die
          alles weten van Magento, de nieuwste ontwikkelen en het bouwen van een
          aantrekkelijke, converterende webshop. Zij kennen zowel Magento 1 als
          Magento 2 door en door dankzij hun jarenlange ervaring en bijbehorende
          certificeringen. Magento Webshop laten maken? Bekijk één van onze
          diensten, of neem direct vrijblijvend contact op met Janko om te
          kijken wat we voor elkaar kunnen betekenen!
        </Text>

        <Button color="tertiary" to="/contact">
          Neem contact op
        </Button>
      </Image>
    </Section>

    <ContactCta />
  </Layout>
);

export default DienstenBouwenMagento;
